













































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SitesServices from "@/services/SitesServices";
import SiteValueId from "@/models/SiteValueId";
import GroupedType5CoefficientSelectedValueByValue from "@/models/GroupedType5CoefficientSelectedValueByValue";
import { Guid } from "guid-typescript";

@Component({
  components: {
    modal: () => import("@/components/Shared/Modal.vue"),
  },
})
export default class CoefficientSitesTableModal extends Vue {
  /**
   * Defines if the modal is opened or not
   */
  @Prop({ default: false }) private readonly show!: boolean;
  /**
   * Property set when the modal is opened
   */
  @Prop({ default: undefined }) private readonly props!: any;
  @Prop({ default: undefined }) private readonly existingElements!: Array<
    GroupedType5CoefficientSelectedValueByValue
  >;
  private loaded: boolean = false;
  // the key is the site id
  private sites: Map<string, any> = new Map<string, any>();
  // the key is the value returned from the function this.existingSitesMapKey
  private existingSiteValue: Map<string, SiteValueId> = new Map<string, any>();
  private isSelectAllClicked = false;
  private selectedSites: Array<string> = [];
  private coefficientSelectedValuesToDelete: Array<string> = [];
  private initialSelectedSites: Array<string> = [];
  private isNewLine: boolean = false;
  private get currentRow(): GroupedType5CoefficientSelectedValueByValue {
    return this.props?.rowData;
  }
  /**
   * Methods
   */

  private selectAll() {
    this.isSelectAllClicked = !this.isSelectAllClicked;
    if (!this.isSelectAllClicked) {
      this.selectedSites = [];
      return;
    }
    this.sites.forEach((value: any, key: string) => {
      if (
        this.isSiteDisabled(this.existingSitesMapKey(key)) ||
        this.selectedSites.indexOf(key) !== -1
      ) {
        return;
      }
      this.selectedSites.push(key);
    });
    this.isSelectAllClicked = this.selectedSites.length === this.sites.size;
  }

  private validate() {
    this.selectSites(this.selectedSites);
    const coefficientSelectedValuesToSave: GroupedType5CoefficientSelectedValueByValue = new GroupedType5CoefficientSelectedValueByValue(
      this.currentRow.coefficientId,
      this.currentRow.bopModelId
    );
    coefficientSelectedValuesToSave.trackId = this.currentRow.trackId;
    coefficientSelectedValuesToSave.value = this.currentRow.value;
    coefficientSelectedValuesToSave.siteValueIdList = this.selectedSites.map(
      (s) => {
        const existingValue = this.getSiteValueFromMap(s);
        const siteValueId = new SiteValueId();
        siteValueId.siteId = s;
        siteValueId.siteName = this.getSiteName(s);
        siteValueId.coefficientSelectedValueId = existingValue
          ? existingValue.coefficientSelectedValueId
          : Guid.create().toString();
        return siteValueId;
      }
    );
    this.props.rowData.siteValueIdList =
      coefficientSelectedValuesToSave.siteValueIdList;
    this.$emit("validate", {
      coefficientSelectedValuesToSave,
      coefficientSelectedValuesToDelete: this.coefficientSelectedValuesToDelete,
    });
  }
  private existingSitesMapKey(siteId: string): string {
    return `${siteId}`;
  }
  private async loadData() {
    const sites = (await SitesServices.getEnabledSites(this.$i18n.locale)).data;
    sites.forEach((el: any) => {
      this.sites.set(el.site.id, el);
    });
    this.existingElements.forEach((g) => {
      g.siteValueIdList.forEach((siteValueId: SiteValueId) => {
        this.addToExistingSiteValueMap(
          siteValueId.siteId,
          siteValueId,
          g.value,
          g.trackId
        );
      });
    });
  }
  private isSiteExists(siteId): boolean {
    return this.existingSiteValue.has(this.existingSitesMapKey(siteId));
  }
  private isSiteDisabled(siteId): boolean {
    const siteValueId = this.getSiteValueFromMap(siteId);
    return (
      this.isSiteExists(siteId) &&
      siteValueId?.trackId !== this.currentRow.trackId
    );
  }
  private removeFromExistingSiteValueMap(siteId: string) {
    this.existingSiteValue.delete(this.existingSitesMapKey(siteId));
  }
  private addToExistingSiteValueMap(
    siteId: string,
    siteValueId: SiteValueId,
    value: number = this.currentRow.value,
    trackId = this.currentRow.trackId
  ) {
    siteValueId.trackId = trackId;
    this.existingSiteValue.set(this.existingSitesMapKey(siteId), siteValueId);
  }
  private getSiteValueFromMap(siteId: string): SiteValueId | undefined {
    return this.existingSiteValue.get(this.existingSitesMapKey(siteId));
  }

  /**
   * Select sites to save and sites to delete
   * @param siteIdsToSelect
   * @private
   */
  private selectSites(siteIdsToSelect: Array<string>) {
    const selectedSites: Array<string> = [];
    const sitesToEnable: Array<string> = this.initialSelectedSites.filter(
      (s) => this.selectedSites.indexOf(s) === -1
    );

    siteIdsToSelect.forEach((siteId) => {
      if (this.isSiteDisabled(siteId)) return;
      selectedSites.push(siteId);
      const siteValueId: SiteValueId = new SiteValueId();
      siteValueId.siteId = siteId;
      siteValueId.siteName = this.getSiteName(siteId);
      const existingSiteValueId = this.getSiteValueFromMap(siteId);
      if (existingSiteValueId) {
        siteValueId.coefficientSelectedValueId =
          existingSiteValueId.coefficientSelectedValueId;
      }
      this.addToExistingSiteValueMap(siteId, siteValueId);
    });
    const coefficientSelectedValuesToDelete: Array<string> = [];
    sitesToEnable.forEach((siteId: string) => {
      const existingSiteValueId = this.getSiteValueFromMap(siteId);
      if (existingSiteValueId) {
        coefficientSelectedValuesToDelete.push(
          existingSiteValueId.coefficientSelectedValueId
        );
        this.removeFromExistingSiteValueMap(siteId);
      }
    });
    this.selectedSites = selectedSites;
    this.coefficientSelectedValuesToDelete = coefficientSelectedValuesToDelete;
  }
  private getSiteName(siteId: string): string {
    return this.sites.get(siteId).translation.name;
  }
  /**
   * Hooks
   */
  public created() {
    this.loaded = false;
    this.loadData().then(() => {
      this.loaded = true;
    });
  }

  /***
   * watchers
   */
  @Watch("show")
  async onShowChange(value: boolean, oldValue: boolean) {
    if (!value) {
      this.selectedSites = [];
      this.isSelectAllClicked = false;
      return;
    }
    const selectedSites: Array<string> = [];
    this.currentRow.siteValueIdList.forEach((siteValueId: SiteValueId) => {
      this.addToExistingSiteValueMap(siteValueId.siteId, siteValueId);
      selectedSites.push(siteValueId.siteId);
    });
    this.selectedSites = selectedSites;
    this.initialSelectedSites = selectedSites.map((s) => s);
    // this.$forceUpdate();
  }
  @Watch("selectedSites")
  onSelectedSitesChange() {
    this.isSelectAllClicked = this.selectedSites.length === this.sites.size;
  }
}
